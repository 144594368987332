/**
*
*	Name:			Peyda Fonts
*	Version:			4.1
*	Author:			Naser Khadem
*	Created on:		March 11, 2019
*	Updated on:		July 17, 2021
*	Website:			http://fontiran.com
*	Copyright:		Commercial/Proprietary Software
--------------------------------------------------------------------------------------
فونت ایران یکان یک نرم افزار مالکیتی محسوب می شود. جهت آگاهی از قوانین استفاده از این فونت ها لطفا به وب سایت (فونت ایران دات کام) مراجعه نمایید
--------------------------------------------------------------------------------------
Peyda fonts are considered a proprietary software. To gain information about the laws regarding the use of these fonts, please visit www.fontiran.com 
--------------------------------------------------------------------------------------
This set of fonts are used in this project under the license: (HMNTFDBK)
--------------------------------------------------------------------------------------
*	
**/

@font-face {
	font-family: payda;
	font-style: normal;
	font-weight: 100;
	src: url('./fonts/woff2/PeydaWebFaNum-Thin.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('./fonts/woff/PeydaWebFaNum-Thin.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
	font-family: payda;
	font-style: normal;
	font-weight: 200;
	src: url('./fonts/woff2/PeydaWebFaNum-ExtraLight.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('./fonts/woff/PeydaWebFaNum-ExtraLight.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
	font-family: payda;
	font-style: normal;
	font-weight: 300;
	src: url('./fonts/woff2/PeydaWebFaNum-Light.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('./fonts/woff/PeydaWebFaNum-Light.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
	font-family: payda;
	font-style: normal;
	font-weight: 500;
	src: url('./fonts/woff2/PeydaWebFaNum-Medium.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('./fonts/woff/PeydaWebFaNum-Medium.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
	font-family: payda;
	font-style: normal;
	font-weight: 600;
	src: url('./fonts/woff2/PeydaWebFaNum-SemiBold.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('./fonts/woff/PeydaWebFaNum-SemiBold.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
	font-family: payda;
	font-style: normal;
	font-weight: 800;
	src: url('./fonts/woff2/PeydaWebFaNum-ExtraBold.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('./fonts/woff/PeydaWebFaNum-ExtraBold.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
	font-family: payda;
	font-style: normal;
	font-weight: 900;
	src: url('./fonts/woff2/PeydaWebFaNum-Black.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('./fonts/woff/PeydaWebFaNum-Black.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
	font-family: payda;
	font-style: normal;
	font-weight: bold;
	src: url('./fonts/woff2/PeydaWebFaNum-Bold.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('./fonts/woff/PeydaWebFaNum-Bold.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
	font-family: payda;
	font-style: normal;
	font-weight: normal;
	src: url('./fonts/woff2/PeydaWebFaNum-Regular.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('./fonts/woff/PeydaWebFaNum-Regular.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
	font-family: DoranFaNum;
	font-style: normal;
	font-weight: 100;
	src: url('./fonts/woff-doran/DoranFaNum-Thin.woff') format('woff'),   
	url('./fonts/woff2-doran/DoranFaNum-Thin.woff2') format('woff2');		
}

@font-face {
	font-family: DoranFaNum;
	font-style: normal;
	font-weight: 300;
	src: url('./fonts/woff-doran/DoranFaNum-Light.woff') format('woff'),   
	url('./fonts/woff2-doran/DoranFaNum-Light.woff2') format('woff2');	
}

@font-face {
	font-family: DoranFaNum;
	font-style: normal;
	font-weight: normal;
	src: url('./fonts/woff-doran/DoranFaNum-Regular.woff') format('woff'),   
	url('./fonts/woff2-doran/DoranFaNum-Regular.woff2') format('woff2');		 
}

@font-face {
	font-family: DoranFaNum;
	font-style: normal;
	font-weight: 500;
	src: url('./fonts/woff-doran/DoranFaNum-Medium.woff') format('woff'),   
	url('./fonts/woff2-doran/DoranFaNum-Medium.woff2') format('woff2');		 
}

@font-face {
	font-family: DoranFaNum;
	font-style: normal;
	font-weight: bold;
	src: url('./fonts/woff-doran/DoranFaNum-Bold.woff') format('woff'),   
	url('./fonts/woff2-doran/DoranFaNum-Bold.woff2') format('woff2');		 
}

@font-face {
	font-family: DoranFaNum;
	font-style: normal;
	font-weight: 800;
	src: url('./fonts/woff-doran/DoranFaNum-ExtraBold.woff') format('woff'),   
	url('./fonts/woff2-doran/DoranFaNum-ExtraBold.woff2') format('woff2');	 
}

:root {
	scroll-behavior: smooth;

	--star:#FFD700;
	--primary-1: #1811b7;
	--primary-2: #0d207c;
	--primary-3: #031E59;
	--primary-4 : #002A94;
	--light-bg:#f9f9fb;
	--light-bg2:#f2f2f4;
	--secondry-1: #d33535;
	--secondry-2: #a60185;
	--secondry-3: #958fee;
	--secondry-4: #ff0000;
	/* --secondry-5: #CE0F0F; */
	--o-prime-1: #00b871;
	--o-prime-2:#63b87a;
	--o-prime-3:#56ccc1;
	--body-1: #eef0f4;
	--body-2: #fff;
	--body-3: #000;
	--body-4: #ececee;
	--body-5: #F3F8Fb;
	
	--text-1: #b0c8e0;
	--text-2: #484849;
	--text-3:#fff;
	--text-4: #b7b7b7;
	--text-5: #000;
	--text-6: #d6d6d6;
	--text-7: #121418;
	--gray-1:#d1d6e5;
	--gray-2:#a3a3ad;
	
	--border-color : #d2d2d2;
	--radius:10px;
	--border-radius: 20px;
	--border-radius-1: 45px;
	--border-radius-2: 8px;
	--border-radius-3: 15px;
	--border-radius-4: 16px;
	--border-radius-5: 30px;
	--border-radius-6: 12px;
	--border-radius-7: 50px;
	--border-radius-8: 22px;
	--border-radius-9: 4px;
	--border-radius-10: 2px;
	--shadow: 0 8px 10px #00000024;
	--shadow-2: 0 8px 15px #00000034;
	--shadow-3: 0 4px 15px #00000034;
	--shadow-4: 0 2px 10px -3px #848484;
	--shadow-5: 0 3px 2px  rgb(0,0,0, 0.1) ; 
	--shadow-6:3px 4px 4px rgba(72, 72, 73, 0.4);;
	--shadow-7: 0 3px 4px  rgb( 0 ,0 , 0 , 0.08);
	--shadow-8: 0 0px 5px rgb( 84 ,84 ,84 , 1);
	--shadow-9: inset 0px 1px 4px rgba(105, 95, 95, 0.21);
	--shadow-10: 0px 3px 6px rgba(166, 168, 179, 0.4);
	--stroke: 0.5px;

	/* --rcicle1:15px;
	--rcicle2:10px; */
	
	/* -----------------------------new style */
	--body-color : #EEF0F4;
	--stand-bg: linear-gradient(180deg, #F2F2F2 0%, #FFF 100%);
	
	--primary-5 : #203569;
	--primary-5h: #162750;
	--primary-6 :#03053D;
	--primary-7 :#0A1020;
	--primary-8 : #0382A0;
	--primary-9 : #0B0723;
	--primary-10 : #275A59;
	--primary-11 : #5B9D60;


	--primary-5-h: #162750;

	--body-6: #F8FAFF;
	--body-7: #1A1D25;

	--text-8:#222222;
	--text-9:#5e5e5e;
	--text-10:#CBCBCB;

	--gray-0: #FFFFFF;
	--gray-3:#E5E5E5;
	--gray-4:#818796;
	--gray-5:#121418;
	--gray-6:#979797;
	--gray-7:#2F2F2F;
	--gray-8:#383E4E;
	--gray-9:#A3A9B9;
	--gray-10:#1E1E1E;
	--gray-11:#C0C7D9;
	--gray-12:#E0E5F2;
	--gray-13:#2C313D;
	--gray-14:#121212;
	--gray-15:#F2F2F2;
	--gray-16:#D1D1D6;
	--gray-17:#292D32;
	--gray-18:#EEEEEE;
	--gray-19:#E9EBF0;


	--secondry-5:#CC2129;
	--secondry-6:#D7702D;
	--secondry-7:#ED303E;
	--secondry-bg : rgba(204, 33, 41, 0.10);
	--primary-bg : rgba(32, 53, 105, 0.10);

	--blur: blur(15px);
	--dark-nav:rgba(10, 16, 32, 0.8);

	--shadow-11:linear-gradient(90deg, rgba(47, 47, 47, 0) 0%, #2F2F2F 100%);
	--shadow-12: 0px 4px 8px rgba(0, 0, 0, 0.25);
	--shadow-13: 0px 4px 16px 2px rgba(0, 0, 0, 0.08);
	--shadow-14: 0px 0px 16px rgba(0, 0, 0, 0.16);
	--shadow-15: 0px 4px 8px 0px rgba(0, 0, 0, 0.08) inset, 0 0 0 0 rgba(0, 0, 0, 0.16);
	--shadow-16: 0px 4px 4px rgba(0, 0, 0, 0.25);
	--shadow-17: 0px 8px 16px 0 rgba(0, 0, 0, 0.16);
	--shadow-18: 0px -4px 8px 0 rgba(0, 0, 0, 0.16) inset;
	--shadow-19: 0px 4px 8px rgba(0, 0, 0, 0.16);
	--shadow-20: 0px -4px 8px 0 rgba(0, 0, 0, 0.16) ;
	--shadow-21: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
	--shadow-22: 0px 4px 8px 0px rgba(0, 0, 0, 0.16) inset, 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
	--shadow-23: 0px 4px 8px 0px rgba(0, 0, 0, 0.16) inset, 0px 16px 16px -9px rgba(0, 0, 0, 0.16);
	--shadow-14: 0px -8px 24px 0px rgba(0, 0, 0, 0.16);
	/* --shadow-15: 0px 4px 8px 0px rgba(0, 0, 0, 0.08); */



	--font-fa:  payda ,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-seri;


	
	--border-style: 1px solid var(--border-color);
	--border-style-2: 1px solid var(--gray-3);
	--border-style-3: 1px solid var(--gray-6);
	--border-style-4: 1px solid var(--gray-10);
	--border-style-5: 1px solid var(--body-2);
	--border-style-6: 1px solid var(--primary-8);
	--border-style-7: 1px solid var(--secondry-5);
	--border-style-8: 1px solid var(--primary-10);

	--main-transition: all 0.3s ease-in-out;

	/* home sizes */
	--fs-home-main-center: 21px;
	--fs-home-search: 14px;
	--fs-home-shop-search: 18px;
	--fs-home-shop-headline: 28px;
	--fs-home-shop-body: 23px;
	--fs-home-fav-headline: 36px;
	--fs-home-fav-price: 32px;
	--fs-home-fav-icon: 43px;
	--fs-user-name:18px;
	--fs-user-name2:16px;
	--fs-nav-second:15px;
	--fs-wheel-btn:20px;
	--fs-shop-btn:24px;
	--fs-home-kharid:46px;

	--back-btn:16px;

	/* about us sizes */
	--fs-about-loc-title-desc: 32px;
	--fs-about-title-desc : 36px;
	--fs-about-text-desc : 20px;
	--fs-about-top: 48px;
	

	/* login size */
	--fs-login-title: 28px;
	--fs-login-text: 14px;
	--fs-login-button: 16px;
	--fs-login-sub: 18px;
	--fs-login-input-ph: 14px;
	--fs-login-input-num: 21px;
	--fs-login-again: 13px;

	/* categories */
	--fs-cat-title:22px;
	--fs-cat-name:20px;


	/* goods sorting v2 */
	--fs-sort-title: 26px;
	--fs-sort-item: 20px;
	--fs-sort-itemactive: 20px;

	
	/* good shop filter */
	--fs-good-filt-input: 17px;
	--fs-good-filt-title: 24px;
	--fs-good-filt-arrow: 32px;

	/* good page size */
	--fs-good-page-name:30px;
	--fs-good-page-detail:22px;
	--fs-good-page-detail2:16px;
	--fs-good-page-toman:21px;
	--fs-good-page-cost:31px;
	--fs-good-page-button:20px;
	--fs-good-page-arrow:15px;
	--fs-good-page-tab:30px;
	--fs-good-page-tab-arrow:28px;

	--fs-good-page-text:24px;
	--fs-good-page-shop-name:30px;
	--fs-good-page-shop-score:44px;
	--fs-good-page-shop-deliver:26px;
	--fs-good-page-shop-rate:16px;
	--fs-good-page-shop-price:40px;
	--fs-good-page-shop-toman:26px;
	--fs-good-page-shop-button:25px;
	--fs-good-page-comment-question:28px;
	--fs-good-page-comment-text:24px;
	--fs-good-page-comment-button:20px;
	--fs-good-page-view:30px;
	--fs-good-page-view-name:23px;
	--fs-good-page-view-toman:19px;
	--fs-good-page-view-price:23px;
	--fs-good-page-view-shop:21px;

	--fs-cat-sub:20px;
	
	/* good comment size */
	--fs-good-comment-top:28px;
	--fs-good-comment-text:23px;
	--fs-good-comment-title:24px;
	--fs-good-comment-input:18px;

	/* sliding cart v2 */
	--fs-title: 24px;
	--fs-sub-title: 20px;
	--stroke-size: 0.5px;
	--fs-price: 18px;
	--fs-price-toman: 11px;


	/* search drawer */
	--fs-search-name: 28px;
	--fs-search-price: 22px;
	--fs-search-price-text: 12px;


	/* footer */
	--fs-bullet: 6px;
	--fs-link: 24px;
	--fs-copyright: 16px;
	--fs-icons: 30px;
	--fs-footer-text:18px;

	/* navbar */
	--fs-search-txt: 20px;
	--fs-nav-login-name:12px;
	--fs-nav-login-text:14px;
	--fs-nav-login-text2:13px;
	--fs-nav-login-button:16px;



	/* shop page */
	--fs-shop-info-top: 22px;
	--fs-shop-info-title: 19px;
	--fs-shop-info-shopname: 37px;
	--fs-shop-info-favs: 28px;
	--fs-shop-info-text: 26px;
	--fs-shop-info-more: 22px;

	--fs-shop-slide1-title: 39px;
	--fs-shop-slide1-subtitle: 30px;


	/* basket side */
	--fs-basket-side-text:16px;
	--fs-basket-side-num:16px;
	--fs-basket-side-cost:16px;
	--fs-basket-side-toman:13px;
	--fs-basket-side-radius:50px;

	/* buying basket */
	--fs-basket-buy-name:15px;
	--fs-basket-buy-num:13px;
	--fs-basket-buy-text:21px;
	--fs-basket-buy-text2:16px;
	--fs-basket-buy-toman:20px;
	--fs-basket-buy-cost:26px;
	--fs-basket-buy-radius:45px;
	--fs-basket-buy-radius1:10px;
	--fs-basket-buy-cart-text:34px;
	--fs-basket-buy-cart-text2:24px;
	--fs-basket-buy-cart-num:35px;
	--fs-basket-buy-cart-cost:19px;
	--fs-basket-buy-cart-toman:15px;
	--fs-basket-buy-err-text:27px;
	--fs-basket-buy-err-button:25px;
	--fs-basket-buy-err-radius:30px;
	--fs-basket-buy-address:22px;
	--fs-basket-buy-address-btn:29px;
	--fs-basket-buy-pay:30px;
	--fs-basket-buy-pay-tit:25px;
	--fs-basket-buy-pay-option:21px;
	--fs-basket-buy-pay-btn:29px;
	--fs-basket-buy-anim:32px;
		

		
	/* factor */
	--fs-factor-title:25px;
	--fs-factor-hbg:60px;

	/* rule */
	--fs-rule:25px;
	--fs-rule-header: 26px;
	--fs-rule-text: 24px;

	/* user orders */
	--fs-ord-text:18px;
	--fs-ord-price:14px;
	--fs-ord-btn:16px;
	--fs-ord-dtl-btn:18px;
	--fs-ord-dtl-txt:18px;
	--fs-ord-dtl-add:21px;
	--fs-ord-cncl-text:18px;

	/* user data */
	--fs-u-data-header:20px;
	--fs-u-data-body:18px;
	--fs-us-data-title:16px;
	--fs-us-data-btn:15px;
	--fs-us-data-checkbox:15px;


	/* user dashboard */
	--fs-dashboard-header: 18px;
	--fs-dashboard-text: 16px;
	--fs-dashboard-tbl-order: 18px;
	--fs-carusel-name:20px;

	/*user address */
	--fs-address-text:20px;
	--fs-address-input:15px;
	--fs-address-btn:18px;

	/* user favorite  */
	--fs-u-fav-name:18px;
	--fs-u-fav-shop:16px;

	/* user affiliate */
	--fs-u-affil-link:16px;
	--fs-u-affil-friend:16px;
	
	/* user wallet */
	--fs-u-wal-validate:12px;
	--fs-u-wal-validate-btn:14px;
	--fs-u-wal-text:16px;
	--fs-u-wal-tranTit:14px;
	--fs-u-pocket-top:16px;
	--fs-u-pocket-left-text:16px;
	--fs-u-pocket-left-btn:14px;

	/* user comment */
	--fs-u-comm-text:18px;

	/* user order cancel
	/* --fs-ord-cncl-text:12px;
	--fs-ord-cncl-text2:13px;
	--fs-ord-cncl-label:15px;
	--fs-ord-cncl-input:11px; */ 


	/* user message */
	--fs-u-msg-list:18px;
	--fs-u-msg-list-v:14px;
	--fs-u-msg-list-srch:15px;
	--fs-u-msg-header:18px;
	--fs-u-msg-sub:16px;
	--fs-u-msg-btn:14px;
	--fs-u-msg-question:13px;
	--fs-u-msg-pop-top:14px;
	--fs-u-msg-pop-text:16px;

	
	/* tab2 */
	--fs-tab-title:19px;

	/* menu pannel */
	--fs-item:17px;
	--fs-hello:14px;
	--fs-name:16px;

	/* seller - products */
	--sell-search-title: 16px;
	--sell-search-text: 16px;
	--sell-search-btn: 16px;
	--sell-filter-title: 16px;
	--sell-filter-text: 13px;

	/* seller new product */
	--sell-new-pro-tit:15px;
	--sell-new-pro-input:14px;

	/* seller button */
	--sell-btn : 16px;
	--seller-signup: 38px;

}
.btnhover:hover{
	background: var(--primary-5-h) !important;
	cursor: pointer;
	opacity: 1;
}
.white-btnhover:hover{
	background: rgba(22, 39, 80, 0.10) !important;
	cursor: pointer;
	opacity: 1;
}
.disable:disabled{

}
.focus:focus{
	

}
.arr{
	fill: var(--gray-6);
}
.like-red{
	fill: var(--secondry-5);
}
.like-gray{
	fill: var(--gray-3);
}
.glass0{
	background: rgba(10, 16, 32, 0.8);
	backdrop-filter: var(--blur);
}
.glass1{
	background: rgba(255, 255, 255, 0.4);
	border: 2px solid rgba(255, 255, 255, 0.01);
	box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
	border-radius: var(--border-radius-9);
	backdrop-filter:var(--blur)
}
.glass2{
	background: rgba(10, 16, 32, 0.4);
	box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
	backdrop-filter: var(--blur);
	border: 2px solid rgba(255, 255, 255, 0.01);
	border-radius: var(--border-radius-9);
}
.glass3{
	background: rgba(10, 16, 32, 0.6);
	backdrop-filter: var(--blur);
	box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
	border: 2px solid rgba(255, 255, 255, 0.01);
	border-radius: var(--border-radius-9);
}
.ttl {
	color: var(--gray-6);
	font-weight: 500;
	font-size: 1rem;
}
.ttlinput {
color: var(--text-8);
margin-right: 0.5rem;
border: none;
font-size: 1rem;
font-weight: 500;
}

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
	background-color: var(--body-4);
	border-radius: 0;
}
::-webkit-scrollbar {
	width: 5px;
}
::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background-color: var(--primary-6);
}

body {
	margin: 0;
	font-family: var(--font-fa);
	color: var(--text-2);
	background-color: var(--body-1);
	/* background-color: ; */
	letter-spacing: -0.25px !important;
	scroll-behavior: smooth;
}
svg{
	font-family:  var(--font-fa);
}
input {
	font-family:  var(--font-fa);
	letter-spacing: -0.25px !important;
}
textarea {
	font-family: var(--font-fa);
	letter-spacing: -0.25px !important;
}
button {
	font-family: var(--font-fa);
	letter-spacing: -0.25px !important;
}
select {
	font-family: var(--font-fa);
	letter-spacing: -0.25px !important;
}
pre{
	font-family:  var(--font-fa);
	letter-spacing: -0.25px !important;
}
input[type='email']:invalid{
	border: 1px solid var(--secondry-1)
}
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  
  input[type="number"]:out-of-range {
	border: 1px solid var(--secondry-1)
  }
.MuiFormLabel-root{
	font-family: var(--font-fa);
	letter-spacing: -0.25px !important;
}

.MuiButton-root,
.MuiInputBase-root,
.MuiInputBase-input,
.MuiAutocomplete-input,
.MuiAutocomplete-popper,
.MuiAutocomplete-inputRoot,
.MuiInputLabel-formControl,
.MuiAutocomplete-option{
	font-size: 1rem !important;
	font-family:  var(--font-fa) !important;
	letter-spacing: -0.25px !important;

}
.MuiPaper-rounded{
	border-radius: var(--border-radius-2);
}
.MuiStep-alternativeLabel{
	display: flex;
	flex-direction: column;
	justify-content: center;
	.MuiStepConnector-alternativeLabel {
		left: auto;
		right: auto;
	}
}
.empty{
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	background-color: var(--body-2);
	border-radius: var(--border-radius-9);

}
.backdark{
	position: fixed;
    inset:100px 0 0;
    background: rgba(0, 0, 0, 0.6);
	backdrop-filter: blur(10px);
	z-index: 996;
}
.backdark2{
	position: fixed;
    inset:0;
    background-color: rgba(0,0,0,0.5);
	z-index: 998;
}
.send-btn{
	position: fixed;
	inset: auto 0 0;
	border-radius: var(--border-radius) var(--border-radius) 0 0;
	margin: 0;
	height: 3.5rem;
	width: 100vw;
	color: var(--text-3);
	background: var(--secondry-3);
	padding: 0.5rem 0;
	font-weight: 500;
	font-size: 1.2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	border: none;
	text-decoration: none;
}
.send-btn:hover{
	box-shadow: var(--shadow-5);
}

.title-home2{
	font-size: 1.25rem;
	font-weight: 700
}
.more-home2{
	color: var(--primary-8);
	font-weight: 700;
	font-size: 0.87rem;
	text-decoration: none;
	cursor: pointer;
	:hover{
		color: var(--primary-6);
	}
}
.devider{
	border-left: var(--gray-6);
	height: 1rem;
	display: flex;
	margin: 0.5rem;
}

@media screen and (max-width: 1919px) and (min-width:1025px) {
	:root {
		/* home sizes */
		--fs-home-main-center: 15px;
		--fs-home-search: 12px;
		--fs-home-shop-search: 15px;
		--fs-home-shop-headline: 19px;
		--fs-home-shop-body: 15px;
		--fs-home-fav-headline: 20px;
		--fs-home-fav-price: 18px;
		--fs-home-fav-icon: 35px;
		--fs-wheel-btn:20px;
		--fs-shop-btn:24px;
		--fs-home-kharid:36px;
		
		--back-btn:14px;

		/* rule */
		--fs-rule:18px;
		--fs-rule-header: 18px;
		--fs-rule-text: 16px;
		
		/* login size */
		--fs-login-title: 19px;
		--fs-login-text: 12px;
		--fs-login-button: 13px;
		--fs-login-sub: 13px;
		--fs-login-input-ph: 11px;
		--fs-login-input-num: 15px;
		--fs-login-again: 13px;

		/* categories */
		--fs-cat-title:18px;
		--fs-cat-name:16px;

		/* about us sizes */
		--fs-about-loc-title-desc:30px;
		--fs-about-title-desc : 28px;
		--fs-about-text-desc : 19px;
		--fs-about-top: 30px;


		/* goods sorting v2 */
		--fs-sort-title: 18px;
		--fs-sort-item: 14px;
		--fs-sort-itemactive: 15px;

		/* good shop */
		--fs-good-page-shop-button:15px;
		--fs-cat-sub:16px;

		/* good shop filter */
		--fs-good-filt-input: 12px;
		--fs-good-filt-title: 16px;
		--fs-good-filt-arrow: 32px;

		/* sliding cart v2 */
		--fs-title: 20px;
		--fs-sub-title: 16px;
		--stroke-size: 0.5px;
		--fs-price: 16px;
		--fs-price-toman: 14px;

		/* search drawer */
		--fs-search-name: 18px;
		--fs-search-price: 15px;
		--fs-search-price-text: 11px;

		/* good page size */
		--fs-good-page-name:21px;
		--fs-good-page-detail:15px;
		--fs-good-page-detail2:16px;
		--fs-good-page-toman:12px;
		--fs-good-page-cost:18px;
		--fs-good-page-button:16px;
		--fs-good-page-arrow:12px;
		--fs-good-page-tab:20px;
		--fs-good-page-tab-arrow:16px;
		--fs-good-page-text:16px;
		--fs-good-page-shop-name:23px;
		--fs-good-page-shop-score:26px;
		--fs-good-page-shop-deliver:18px;
		--fs-good-page-shop-rate:12px;
		--fs-good-page-shop-price:23px;
		--fs-good-page-shop-toman:17px;
		--fs-good-page-shop-button:18px;
		--fs-good-page-comment-question:20px;
		--fs-good-page-comment-text:16px;
		--fs-good-page-comment-button:16px;
		--fs-good-page-view:30px;
		--fs-good-page-view-name:17px;
		--fs-good-page-view-toman:14px;
		--fs-good-page-view-price:17px;
		--fs-good-page-view-shop:16px;
		--fs-good-page-view-arrow:30px;
		--fs-basket-buy-address:15px;
		--fs-basket-buy-address-btn:16px;
		--fs-basket-buy-pay:21px;
		--fs-basket-buy-pay-tit:15px;
		--fs-basket-buy-pay-option:14px;
		--fs-basket-buy-pay-btn:18px;


		/* good comment size */
		--fs-good-comment-top:20px;
		--fs-good-comment-text:16px;
		--fs-good-comment-title:18px;
		--fs-good-comment-input:12px;

		/* footer */
		--fs-bullet: 5px;
		--fs-link: 17px;
		--fs-copyright: 14px;
		--fs-icons: 24px;
		--fs-footer-text:16px;


		/* navbar */
		--fs-search-txt: 15px;
		--fs-nav-login-name:12px;
		--fs-nav-login-text:13px;
		--fs-nav-login-text2:12px;
		--fs-nav-login-button:14px;


		/* shop page */
		--fs-shop-info-top: 15px;
		--fs-shop-info-title: 13px;
		--fs-shop-info-shopname: 26px;
		--fs-shop-info-favs: 20px;
		--fs-shop-info-text: 13px;
		--fs-shop-info-more: 14px;
    
		--fs-shop-slide1-title: 27px;
		--fs-shop-slide1-subtitle: 21px;
    
    	--fs-side-fav-arrow:25px;

		/* basket side */
		--fs-basket-side-text:13px;
		--fs-basket-side-num:14px;
		--fs-basket-side-cost:18px;
		--fs-basket-side-toman:13px;
		--fs-basket-side-radius:30px;

		/* buying basket */
		--fs-basket-buy-name:25px;
		--fs-basket-buy-text:14px;
		--fs-basket-buy-text2:12px;
		--fs-basket-buy-toman:15px;
		--fs-basket-buy-cost:18px;
		--fs-basket-buy-cart-text:25px;
		--fs-basket-buy-cart-text2:14px;
		--fs-basket-buy-cart-cost:15px;
		--fs-basket-buy-cart-toman:13px;
		--fs-basket-buy-cart-num:26px;
		--fs-basket-buy-err-text:17px;
		--fs-basket-buy-err-button:15px;
		--fs-basket-buy-err-radius:25px;


		/* user dashboard */
		--fs-dashboard-header: 13px;
		--fs-dashboard-text: 12px;
		--fs-dashboard-tbl-order: 12px;
		--fs-carusel-name:14px;

		/*user address */
		--fs-address-text:14px;
		--fs-address-input:12px;
		--fs-address-btn:13px;
		--fs-address-box-btn:15px;
		--fs-address-box-input:11px;

		/* user orders */
		--fs-ord-text:12px;
		--fs-ord-price:14px;
		--fs-ord-btn:12px;
		--fs-ord-dtl-btn:13px;
		--fs-ord-dtl-txt:12px;
		--fs-ord-dtl-txt2:13px;
		--fs-ord-dtl-txt3:13px;
		--fs-ord-dtl-add:14px;
		--fs-ord-cancel:16px;

		/* user order comment */
		--fs-ord-cmd-title:15px;
		--fs-ord-cmd-name:13px;
		--fs-ord-cmd-text:11px;
		--fs-ord-cmd-btn:13px;

		/* user order cancel */
		--fs-ord-cncl-text:14px;
		--fs-ord-cncl-text2:12px;
		--fs-ord-cncl-label:15px;
		--fs-ord-cncl-input:11px;


		/* user wallet */
		--fs-u-wal-validate:11px;
		--fs-u-wal-validate-btn:13px;
		--fs-u-wal-text:12px;
		--fs-u-wal-tranTit:13px;
		--fs-u-pocket-top:11px;
		--fs-u-pocket-left-text:14px;
		--fs-u-pocket-left-btn:12px;

		/* user comment */
		--fs-u-comm-text:12px;

		/* user message */
		--fs-u-msg-list:14px;
		--fs-u-msg-list-v:12px;
		--fs-u-msg-list-srch:12px;
		--fs-u-msg-header:12px;
		--fs-u-msg-sub:11px;
		--fs-u-msg-btn:12px;
		--fs-u-msg-question:12px;

		/* user favorite  */
		--fs-u-fav-name:14px;
		--fs-u-fav-shop:13px;
	

		/* user affiliate */
		--fs-u-affil-link:16px;
		--fs-u-affil-friend:16px;
	
		/* tab2 */
		--fs-tab-title:14px;
		--fs-u-title-text:16px;
		--fs-u-empty:20px;

		/* factor */
		--fs-factor-title:30px;
		--fs-factor-hbg:45px;

		/* menu pannel */
		/* --fs-item:17px;
		--fs-hello:14px;
		--fs-name:16px; */

		/* seller order */
		--fs-s-or-title:13px;
		--fs-s-or-text:13px;
		
		/* seller calcute */
		--fs-s-cal-title :18px;

		/* seller */
		--seller-signup: 16px;
	}

	
}
@media screen and (max-width: 1150px){
	:root{
		--fs-basket-buy-pay-option:15px;

		--back-btn:11px;

		/* search drawer */
		--fs-search-name: 15px;
		--fs-search-price: 12px;
		--fs-search-price-text: 8px;
	}
}
@media screen and (max-width: 1025px){
	:root{
		/* home sizes */
		--fs-home-main-center: 15px;
		--fs-home-search: 12px;
		--fs-home-shop-search: 15px;
		--fs-home-shop-headline: 19px;
		--fs-home-shop-body: 15px;
		--fs-home-fav-headline: 18px;
		--fs-home-fav-price: 21px;
		--fs-home-fav-icon: 29px;
		--fs-home-fav-icon:20px;

		--back-btn:11px;

		/* search drawer */
		--fs-search-name: 18px;
		--fs-search-price: 15px;
		--fs-search-price-text: 11px;

		/* about us sizes */
		

		/* goods sorting v2 */
		--fs-sort-title: 18px;
		--fs-sort-item: 14px;
		--fs-sort-itemactive: 15px;

		/* good shop */
		--fs-good-page-shop-button:12px;
		--fs-good-page-view-name:14px;
		--fs-good-page-view-shop:12px;
		--fs-good-page-view-toman:12px;
		--fs-good-page-view-price:13px;


		/* good shop filter */
		--fs-good-filt-input: 12px;
		--fs-good-filt-title: 16px;
		--fs-good-filt-arrow: 14px;

		/* sliding cart v2 */
		--fs-title: 18px;
		--fs-sub-title: 14px;
		--stroke-size: 0.5px;
		--fs-price: 14px;
		--fs-price-toman: 14px;

		/* good page size */
		--fs-good-page-name:21px;
		--fs-good-page-detail:15px;
		--fs-good-page-detail2:16px;
		--fs-good-page-toman:15px;
		--fs-good-page-cost:22px;
		--fs-good-page-button:15px;
		--fs-good-page-tab:18px;
		--fs-good-page-text:16px;
		--fs-good-page-shop-name:28px;
		--fs-good-page-shop-score:27px;
		--fs-good-page-shop-deliver:19px;
		--fs-good-page-shop-rate:12px;
		--fs-good-page-shop-price:27px;
		--fs-good-page-shop-toman:19px;
		--fs-good-page-shop-button:18px;
		--fs-good-page-comment-question:20px;
		--fs-good-page-comment-text:18px;
		--fs-good-page-comment-button:16px;


		/* good comment size */
		--fs-good-comment-top:20px;
		--fs-good-comment-text:16px;
		--fs-good-comment-title:18px;
		--fs-good-comment-input:12px;

		/* footer */
		--fs-bullet: 5px;
		--fs-link: 17px;
		--fs-copyright: 12px;
		--fs-icons: 24px;
		--fs-footer-text:12px;


		/* navbar */
		--fs-search-txt: 16px;

		/* shop page */
		--fs-shop-info-top: 15px;
		--fs-shop-info-title: 13px;
		--fs-shop-info-shopname: 26px;
		--fs-shop-info-favs: 20px;
		--fs-shop-info-text: 13px;

		--fs-shop-slide1-title: 27px;
		--fs-shop-slide1-subtitle: 21px;

		/* basket */
		--fs-basket-buy-address:16px;
		--fs-basket-buy-address-btn:14px;
		--fs-basket-buy-pay:15px;
		--fs-basket-buy-pay-tit:12px;
		--fs-basket-buy-pay-btn:18px;
		--fs-basket-buy-cart-cost:15px;
		--fs-basket-buy-cart-text: 20px;
		--fs-basket-buy-cart-text2: 19px;
		--fs-basket-buy-cart-num: 21px;
		--fs-basket-buy-text: 16px;
		--fs-basket-buy-toman: 18px;
		--fs-basket-buy-cost: 19px;


		/* order */
		--fs-ord-dtl-add:13px;
		--fs-ord-text:14px;
		--fs-ord-btn:12px;
		--fs-ord-dtl-btn:13px;
		--fs-ord-dtl-txt:15px;
		--fs-address-btn:15px;

		/* user  */
		--fs-dashboard-text:11px;
		--fs-dashboard-header:14px;
		--fs-dashboard-tbl-order:13px;
		--fs-u-data-header:16px;
		--fs-us-data-title:14px;
		--fs-us-data-btn:13px;

		--fs-u-msg-list: 13px;
		--fs-s-or-title:15px;
		--fs-u-comm-text: 14px;
		--fs-u-wal-text: 12px;
		--fs-carusel-name:14px;

		--fs-u-wal-tranTit:11px;

		/* tab2 */
		--fs-tab-title:14px;

		/* search drawer */
		--fs-search-name: 15px;
		--fs-search-price: 12px;
		--fs-search-price-text: 8px;

		--fs-good-page-tab-arrow:16px;
		--fs-basket-buy-err-text: 18px;
    	--fs-basket-buy-err-button:16px
	}	
}
@media (hover :hover)  and (max-width: 850px){
	a , div , input ,img { 
		transition: none; 
		transform: none;
		animation: none;
		cursor: default;
	}
}
@media screen and (max-width: 850px){
	:root {
		/* home sizes */
		--fs-home-main-center: 15px;
		--fs-home-search: 12px;
		--fs-home-shop-search: 15px;
		--fs-home-shop-headline: 19px;
		--fs-home-shop-body: 15px;
		--fs-home-fav-headline: 26px;
		--fs-home-fav-price: 21px;
		--fs-home-fav-icon: 20px;
		--fs-user-name:14px;
		--fs-wheel-btn:14px;
		--fs-shop-btn:14px;
		--fs-home-kharid:20px;

		--back-btn:11px;


		/* login */
		--fs-nav-login-button:11px;

		/* rule */
		--fs-rule:15px;
		--fs-rule-header: 14px;
		--fs-rule-text: 13px;

		/* categories */
		--fs-cat-title:15px;
		--fs-cat-name:12px;

		/* about us sizes */
		--fs-about-loc-title-desc : 24px;
		--fs-about-title-desc : 20px;
		--fs-about-text-desc : 13px;
		--fs-about-top: 32px;


		/* goods sorting v2 */
		--fs-sort-title: 16px;
		--fs-sort-item: 12px;
		--fs-sort-itemactive: 13px;

		/* good shop filter */
		--fs-good-filt-input: 10px;
		--fs-good-filt-title: 12px;
		--fs-good-filt-arrow: 11px;

		/* sliding cart v2 */
		--fs-title: 16px;
		--fs-sub-title: 12px;
		--stroke-size: 0.5px;
		--fs-price: 18px;
		--fs-price-toman: 11px;

		
		/* footer */
		--fs-bullet: 5px;
		--fs-link: 15px;
		--fs-copyright: 12px;
		--fs-icons: 24px;
		--fs-footer-text:10px;


		/* navbar */
		--fs-search-txt: 16px;
	
		--fs-send-tit:13px;
		--fs-cat-sub:14px;
	}
	/* .title-home2{
		font-size: 1rem;
	}
	.more-home2{
		font-size: 0.75rem;
	} */
}
@media screen and (max-width: 500px){
	:root {
		/* home sizes */
		--fs-home-main-center: 19px;
		--fs-home-search: 12px;
		--fs-home-shop-search: 16px;
		--fs-home-shop-headline: 18px;
		--fs-home-shop-body: 15px;
		--fs-home-fav-headline: 26px;
		--fs-home-fav-price: 21px;
		--fs-home-fav-icon: 16px;
		--back-btn:11px;

		/* about us sizes
		--fs-about-title-desc : 16px;
		--fs-about-text-desc : 12px; */

		/* login size */
		--fs-login-title: 21px;
		--fs-login-text: 14px;
		--fs-login-button: 15px;
		--fs-login-sub: 15px;
		--fs-login-input-ph: 14px;
		--fs-login-input-num: 16px;
		--fs-login-again: 13px;

		/* sliding cart v2 */
		--fs-title: 12px;
		--fs-sub-title: 11px;
		--stroke-size: 0.5px;
		--fs-price: 13px;
		--fs-price-toman: 11px;

		/* shop cart size */
		--fs-cart-name:13px;
		--fs-cart-number:14px;
		--fs-cart-toman:11px;

		/* good page size */
		--fs-good-page-name:20px;
		--fs-good-page-detail:15px;
		--fs-good-page-detail2:12px;
		--fs-good-page-toman:15px;
		--fs-good-page-cost:22px;
		--fs-good-page-button:23px;
		--fs-good-page-tab:14px;

		/* --fs-good-page-text:24px; */
		--fs-good-page-shop-name:20px;
		--fs-good-page-shop-score:29px;
		--fs-good-page-shop-deliver:19px;
		--fs-good-page-shop-rate:29px;
		--fs-good-page-shop-price:24px;
		--fs-good-page-shop-toman:19px;
		--fs-good-page-shop-button:13px;
		--fs-good-page-comment-question:14px;
		--fs-good-page-comment-text:12px;
		--fs-good-page-comment-button:12px;
		--fs-good-page-view:22px;
		--fs-good-page-view-name:14px;
		--fs-good-page-view-toman:15px;
		--fs-good-page-view-price:22px;
		--fs-good-page-view-shop:11px;
		
		/* good comment size */
		--fs-good-comment-top:12px;
		--fs-good-comment-title:14px;
		--fs-good-comment-input:16px;

		/* basket side */
		--fs-basket-side-text:12px;
		--fs-basket-side-num:12px;
		--fs-basket-side-cost:16px;
		--fs-basket-side-toman:12px;
		--fs-basket-side-radius:40px;

		/* buying basket */
		--fs-basket-buy-name:15px;
		--fs-basket-buy-text:13px;
		--fs-basket-buy-num:13px;
		--fs-basket-buy-button:23px;
		--fs-basket-buy-cost:21px;
		--fs-basket-buy-toman:15px;
		--fs-basket-buy-radius:45px;
		--fs-basket-buy-radius1:10px;
		--fs-basket-buy-cart-text:25px;
		--fs-basket-buy-cart-text2:14px;
		--fs-basket-buy-cart-num:25px;
		--fs-basket-buy-cart-cost:19px;
		--fs-basket-buy-cart-toman:15px;
		--fs-basket-buy-err-text:16px;
		--fs-basket-buy-err-button:18px;
		--fs-basket-buy-err-radius:15px;
		--fs-basket-buy-address:10px;
		--fs-basket-buy-address-btn:12px;
		--fs-basket-buy-pay:12px;
		--fs-basket-buy-pay-tit:10px;
		--fs-basket-buy-pay-option:14px;
		--fs-basket-buy-pay-btn:18px;
		--fs-basket-buy-anim:32px;
		--fs-basket-buy-address:16px;
		--fs-basket-buy-address-btn:15px;
		--fs-basket-buy-address-text:14px;
		--fs-basket-buy-address-input:18px;


		/* factor */
		--fs-factor-title:20px;
		--fs-factor-hbg:35px;
		--fs-footer-text:14px;

	
		/* navbar */
		--fs-nav-login-name:17px;
		--fs-nav-login-text:16px;
		--fs-nav-login-text2:14px;
		--fs-nav-login-button:17px;


		/* user menu */
		--fs-menu-user-mobile:14px;
		--fs-menu-user-name:17px;
		--fs-menu-top-title:13px;
		--fs-menu-item:16px;
		--fs-menu-back:16px;
		--fs-menu-lastseen:13px;
		--fs-menu-lastseen-name:13px;
		--fs-menu-lastseen-price:14px;

		/*user address */
		--fs-address-text:14px;
		--fs-address-input:12px;
		--fs-address-btn:13px;
		--fs-address-box-btn:15px;
		--fs-address-box-input:11px;

		/* user orders */
		--fs-ord-text:13px;
		--fs-ord-price:15px;
		--fs-ord-btn:16px;
		--fs-ord-dtl-btn:18px;
		--fs-ord-dtl-txt:14px;
		--fs-ord-dtl-txt2:15px;
		--fs-ord-dtl-txt3:16px;
		--fs-ord-dtl-add:13px;
		--fs-ord-cancel:19px;
		
		/* user order comment */
		--fs-ord-cmd-title:18px;
		--fs-ord-cmd-name:16px;
		--fs-ord-cmd-text:12px;
		--fs-ord-cmd-btn:19px;

		/* user order cancel */
		--fs-ord-cncl-text:13px;
		--fs-ord-cncl-text2:15px;
		--fs-ord-cncl-label:18px;
		--fs-ord-cncl-input:12px;

		/* user data */
		--fs-us-data-title:14px;
		--fs-us-data-btn:17px;
		--fs-us-data-checkbox:13px;
			
		/* user comment */
		--fs-u-comm-text:14px;

		/* user message */
		--fs-u-msg-pop-top:13px;
		--fs-u-msg-pop-text:15px;

		/* user wallet */
		--fs-u-pocket-top:14px;
		--fs-u-pocket-left-text:14px;
		--fs-u-pocket-left-btn:16px;
		--fs-u-wal-validate:14px;
		--fs-u-wal-validate-btn:16px;
		--fs-u-wal-text:14px;
		--fs-u-wal-tranTit:12px;

		/* tab2 */
		--fs-tab-title:13px;
		--fs-u-empty:15px;

		/* seller */
		--fs-s-or-title:12px;
		--seller-signup:15px;

	}
	
}
@media screen and (max-width: 400px){
	:root {
		/* home sizes */
		--fs-home-main-center: 16px;
		--fs-home-search: 16px;
		--fs-home-shop-search: 16px;
		--fs-home-shop-headline: 15px;
		--fs-home-shop-body: 13px;
		--fs-home-fav-headline: 26px;
		--fs-home-fav-price: 21px;
		--fs-home-fav-icon: 13px;
		
		--back-btn:11px;

		/* about us sizes */
	
		
		/* login size */
		--fs-login-title: 17px;
		--fs-login-text: 16px;
		--fs-login-button: 12px;
		--fs-login-sub: 15px;
		--fs-login-input-ph: 14px;
		--fs-login-input-num: 16px;
		--fs-login-again: 11px;


	
		/* shop cart size */
		--fs-cart-name:11px;
		--fs-cart-number:12px;
		--fs-cart-toman:11px;

		/* good page size */
		--fs-good-page-name:15px;
		--fs-good-page-detail:11px;
		--fs-good-page-detail2:10px;
		--fs-good-page-toman:12px;
		--fs-good-page-cost:16px;
		--fs-good-page-button:17px;
		--fs-good-page-tab:12px;
		--fs-good-page-tab-arrow:14px;
		--fs-good-page-text:12px;
		--fs-good-page-shop-name:16px;
		--fs-good-page-shop-score:19px;
		--fs-good-page-shop-deliver:15px;
		/* --fs-good-page-shop-rate:16px; */
		--fs-good-page-shop-price:19px;
		--fs-good-page-shop-toman:19px;
		--fs-good-page-shop-button:11px;
		--fs-good-page-comment-question:12px;
		--fs-good-page-comment-text:12px;
		--fs-good-page-comment-button:11px;
		

		--fs-good-filt-input: 16px;

		/* good comment size */
		--fs-good-comment-top:12px;
		--fs-good-comment-title:12px;
		--fs-good-comment-input:16px;

		/* buying basket */
		--fs-basket-buy-name:12px;
		--fs-basket-buy-text:12px;
		--fs-basket-buy-num:12px;
		--fs-basket-buy-button:18px;
		--fs-basket-buy-cost:16px;
		--fs-basket-buy-toman:12px;
		--fs-basket-buy-radius:40px;
		--fs-basket-buy-radius1:10px;
		--fs-basket-buy-cart-text:19px;
		--fs-basket-buy-cart-text2:12px;
		--fs-basket-buy-cart-num:19px;
		--fs-basket-buy-cart-cost:15px;
		--fs-basket-buy-cart-toman:12px;
		--fs-basket-buy-address-btn:12px;
		--fs-basket-buy-pay:15px;
		--fs-basket-buy-pay-tit:12px;
		--fs-basket-buy-pay-option:14px;
		--fs-basket-buy-pay-btn:18px;
		--fs-basket-buy-anim:32px;
		--fs-basket-buy-address:12px;
		--fs-basket-buy-address-btn:14px;
		--fs-basket-buy-address-text:13px;
		--fs-basket-buy-err-text:14px;
		--fs-basket-buy-err-button:12px;

		
		/* navbar */
		--fs-nav-login-name:15px;
		--fs-nav-login-text:14px;
		--fs-nav-login-text2:12px;
		--fs-nav-login-button:17px;


		/* user menu */
		--fs-menu-user-mobile:13px;
		--fs-menu-user-name:15px;
		--fs-menu-top-title:12px;
		--fs-menu-item:14px;
		--fs-menu-back:13px;
		--fs-menu-lastseen:12px;
		--fs-menu-lastseen-name:12px;
		--fs-menu-lastseen-price:13px;

		/* user data */
		--fs-us-data-title:12px;
		--fs-us-data-btn:15px;
		--fs-us-data-checkbox:11px;

		/* user comment */
		--fs-u-comm-text:12px;

		/* user orders */
		--fs-ord-text:11px;
		--fs-ord-price:13px;
		--fs-ord-btn:16px;
		--fs-ord-dtl-btn:18px;
		--fs-ord-dtl-txt:12px;
		--fs-ord-dtl-txt2:13px;
		--fs-ord-dtl-txt3:13px;
		--fs-ord-dtl-add:12px;
		--fs-ord-cancel:16px;

		/* user order comment */
		--fs-ord-cmd-title:15px;
		--fs-ord-cmd-name:13px;
		--fs-ord-cmd-text:15px;
		--fs-ord-cmd-btn:13px;

		/* user order cancel */
		--fs-ord-cncl-text:12px;
		--fs-ord-cncl-text2:13px;
		--fs-ord-cncl-label:15px;
		--fs-ord-cncl-input:16px;

		/* user message */
		--fs-u-msg-list:11px;
		--fs-u-msg-list-v:12px;
		--fs-u-msg-list-srch:12px;
		--fs-u-msg-header:14px;
		--fs-u-msg-sub:12px;
		--fs-u-msg-btn:12px;
		--fs-u-msg-question:12px;
		--fs-u-msg-pop-top:12px;
		--fs-u-msg-pop-text:16px;
	
		/* user affiliate */
		--fs-u-affil-link:12px;
		--fs-u-affil-friend:14px;
		--fs-u-affil-friend2:16px;
		--fs-u-affil-top:12px;
		--fs-u-affil-btn:19px;

		/* user favorite  */
		--fs-u-fav-name:13px;
		--fs-u-fav-shop:11px;
		
		/* user wallet */
		--fs-u-pocket-top:12px;
		--fs-u-pocket-left-text:12px;
		--fs-u-pocket-left-btn:13px;
		--fs-u-wal-validate:12px;
		--fs-u-wal-validate-btn:14px;
		--fs-u-wal-text:12px;
		--fs-u-wal-tranTit:11px;

		/* tab2 */
		--fs-tab-title:11px;
	}
}
@media screen and (min-width : 1440px) {
	:root{
		--padding : calc(calc(100vw - 72.5rem) / 2);
		--width-padding : calc( var(--padding) * 2 );
		--top-gap : 110px;
		--padding-top: 120px;
	}
}
@media screen and (max-width : 1439px) {
	:root{
		--padding : 6.3rem;
		--width-padding : calc( var(--padding) * 2 );
		--top-gap : 110px;
		--padding-top: 120px;
	}
}
@media screen and (max-width : 1000px) {
	:root{
		--padding : 5.75rem;
		--top-gap : 110px;
	}
}
@media screen and (max-width : 850px) {
	:root{
		--padding : 1rem;
		--profile-padding: 1.2rem;
		--profile-width: calc( var(--profile-padding) * 2 );
		--top-gap : 110px;
		--padding-top: 120px;

	}
}
@media screen and (max-width : 380px) {
	:root{
		--padding : 1rem
	}
}